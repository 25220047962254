import React, {useEffect} from 'react'
import { graphql, Link } from "gatsby"
import Header from '../components/header'
import Footer from '../components/footer'
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink/index"
import Helmet from "react-helmet"

const ProjectTypes = (props) => {
  useEffect(() => {

  })

  return (
    <main className={'is-dark'} style={{backgroundColor: '#000'}}>
      <Helmet
        bodyAttributes={{
          class: [
            'sheyi-section'
          ]
        }}
      />

      <Header isSheyi={true} className={'is-dark'} background={'#000'} {...props} />

      <section className="main-content">
        <div className="container-fluid pos-rel">
          <h1 className={"main"}>Projects</h1>

          {props.data.allSheyifeatures.edges.map((type, key) => {
            if(!type.node.image.url)
              return null

            return (
              <AniLink fade key={key} to={"/sheyi-bankale-projects/" + type.node.slug} className="project">
                <div className="row">
                  <div className="col-md-9">
                    <picture>
                      <img src={type.node.image.url} />
                    </picture>
                  </div>

                  <div className="col-md-3">
                    <h3>{type.node.title}</h3>
                  </div>
                </div>
              </AniLink>
            )
          })}

          <h1 className={"main"}>Curriculum Vitae</h1>

          <div className="row">
            {props.data.allSheyilistings.edges.map((type, key) => {
              if (!type.node.image.sidebar)
                return null

              return (
                <div key={key} className="col-6">
                  <AniLink fade direction={"left"} className="article project"
                           to={"/sheyi-bankale-projects/" + type.node.slug}>
                    <div className="row">
                      <div className="col-md-6">
                        <picture>
                          <img src={type.node.image.sidebar}/>
                        </picture>
                      </div>

                      <div className="col-md-6">
                        <h3>{type.node.title}</h3>
                      </div>
                    </div>
                  </AniLink>
                </div>
              )
            })}
          </div>
        </div>
      </section>

      <Footer isSheyi={true} {...props} />
    </main>
  )
}

export const query = graphql`
  {
    allSheyifeatures {
      edges {
        node {
          title
          slug
          image {
            url
          }
        }
      }
    }
    allSheyilistings {
      edges {
        node {
          title
          slug
          image {
            sidebar
          }
        }
      }
    }
  }
`

export default ProjectTypes